import * as React from 'react'
// hooks
import { useAuth0 } from '@auth0/auth0-react';

// ----------------------------------------------------------------------
const Login = () => {
    useAuth0().logout();
    return <></>;//<div>Logout...</div>;
}

export default Login